import type { HttpClient } from '@wix/http-client'
import { viewerAutomationsClientCreator } from '@wix/wix-code-automations-client'
import { keys } from '@wix/wix-data-client-common-standalone'
import type { FieldType } from '@wix/wix-data-schema-types'

interface AutomationsV2Client {
  reportFormEventToAutomationCreator: () => (
    payload: SubmissionDescriptor,
    collectionId: string,
    pageId: string,
  ) => void
}

interface SubmissionDescriptor {
  detailedEventPayload: {
    [key: string]: {
      value: string
      keyName: string
      index: number
      valueType: FieldType
    }
  }
  eventUTCTime: Date
}

interface TriggerPayload {
  formId: string
  submissionTime: Date
  collectionId: string
  pageId: string
  fields: {
    [key: string]: {
      displayName: string
      value: string | number | boolean | Date | string[]
      type: FieldType
    }
  }
}

export const createAutomationsV2Client = (
  httpClient: HttpClient,
): AutomationsV2Client => {
  const automationsV1Client = viewerAutomationsClientCreator({
    httpClient,
  })
  return {
    reportFormEventToAutomationCreator:
      () =>
      async (
        payload: SubmissionDescriptor,
        collectionId: string,
        pageId: string,
      ): Promise<void> => {
        const formId = payload.detailedEventPayload['form-id'].value
        const submittedKeys = keys(payload.detailedEventPayload).filter(
          key => key !== 'form-id',
        ) as string[]

        const fieldValues = submittedKeys.reduce(
          (fieldValues, key) => {
            // TODO avoid this mangling by achieving the needed fieldKey format in https://github.com/wix-private/dbsm-viewer-app/blob/bcab344b31becc1c77555715a9377110e9fae146/dbsm-viewer-app/src/inverted-dependencies/helpers/automationUtils.js#L63 when specs.wixDataClient.AutomationsClientV2 and the broader Automations V2 is merged
            const fieldKey = key.split('.').join(':')
            const {
              keyName: displayName,
              valueType: type,
              value,
            } = payload.detailedEventPayload[key]
            fieldValues[fieldKey] = { displayName, value, type }
            return fieldValues
          },
          {} as TriggerPayload['fields'],
        )

        const triggerPayload: TriggerPayload = {
          formId,
          pageId,
          submissionTime: new Date(),
          fields: fieldValues,
          collectionId,
        }

        await httpClient.post(
          '/_serverless/cms-automations/report-cms-form-submission',
          triggerPayload,
        )

        // remove the two statements below, when:
        // specs.wixDataClient.AutomationsClientV2 is merged
        // AutomationsV1 triggers reach end-of-life (deprecated and migrated away from)
        const reportFormEventToAutomationV1 =
          automationsV1Client.reportFormEventToAutomationCreator()
        await reportFormEventToAutomationV1(payload)
      },
  }
}
