const APP = 'dataBinding'
const TYPE = 'viewerStatusForEditor'

export type ViewerStatus = {
  datasetSize: Record<string, number>
}

export const createSenderOfViewerStatusForEditor = ({
  global,
  postMessage,
  enabled,
}: {
  global: any
  postMessage: (message: any) => void
  enabled: boolean
}) => {
  const viewerStatus: ViewerStatus = { datasetSize: {} }
  if (global._dataBindingViewerStatusForEditorInterval) {
    global.clearInterval(global._dataBindingViewerStatusForEditorInterval)
  }
  if (enabled) {
    global._dataBindingViewerStatusForEditorInterval = global.setInterval(
      () => {
        postMessage({
          app: APP,
          type: TYPE,
          message: JSON.stringify(viewerStatus),
        })
      },
      1000,
    )
  }
  return {
    update: (fn: (viewerStatus: ViewerStatus) => void) => {
      if (enabled) {
        fn(viewerStatus)
      }
    },
  }
}
