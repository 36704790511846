(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("_"));
	else if(typeof define === 'function' && define.amd)
		define("dbsm-viewer-app", ["_"], factory);
	else if(typeof exports === 'object')
		exports["dbsm-viewer-app"] = factory(require("_"));
	else
		root["dbsm-viewer-app"] = factory(root["_"]);
})((typeof self !== 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__3185__) => {
return 