import type Experiments from '@wix/wix-experiments'
import type { Platform } from './contract'

export type Features = ReturnType<typeof createFeatures>

export const createFeatures = ({
  experiments,
  settings: { env },
  overrides,
}: {
  experiments: Experiments
  settings: Platform['settings']
  overrides?: { [expName: string]: boolean }
}) => ({
  get fes() {
    return experiments.enabled('specs.wixDataViewer.EnableFES')
  },
  get warmupData() {
    return env.live
  },
  get newCurrentPageIndex() {
    return experiments.enabled('specs.wixDataViewer.NewCurrentPageIndex')
  },
  get automationsClientV2() {
    return experiments.enabled('specs.wixDataClient.AutomationsClientV2')
  },
  get filteredResultsCounter() {
    return experiments.enabled(
      'specs.wixDataClient.BlitzNoFilteredResultsCounter',
    )
  },
  get paginationSeoFriendly() {
    if (
      overrides &&
      Object.prototype.hasOwnProperty.call(
        overrides,
        'specs.wixDataViewer.PaginationSeoFriendly',
      )
    ) {
      return overrides['specs.wixDataViewer.PaginationSeoFriendly']
    }
    return experiments.enabled('specs.wixDataViewer.PaginationSeoFriendly')
  },
  get useCloudDataUrlWithBaseExternalUrl() {
    return experiments.enabled(
      'specs.wixDataViewer.UseCloudDataUrlWithBaseExternalUrl',
    )
  },
  get fetchOnlyConnectedFields() {
    return (
      experiments.enabled('specs.wixDataViewer.fetchOnlyConnectedFields') &&
      !env.veloCodeIsPresentOnCurrentPage &&
      !env.livePreview
    )
  },
})
