export function cleanObject(obj?: { [x: string]: unknown }) {
  if (obj === undefined) {
    return undefined
  }
  const objectCopy: { [x: string]: unknown } = {}
  for (const prop in obj) {
    if (obj[prop] !== undefined) {
      objectCopy[prop] = obj[prop]
    }
  }
  return objectCopy
}
