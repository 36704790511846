import { FieldType } from '@wix/wix-data-schema-types'
import type { DatabindingApiContext } from '../../databinding-api/types'
import type { Logger } from '../../logger'
import { transformFromRecordToView } from '../transformData'
import { evaluateExpression, getExpressionFunctions } from '@wix/expressions'
import type { RecordStoreRecord } from '../../record-store/service'
import { keys } from '@wix/wix-data-client-common-standalone'

const addMissingBooleanFields = (
  record: RecordStoreRecord,
  getSchema: DatabindingApiContext['getSchema'],
) => {
  const missingBooleanFields = getSchema().fold(
    () => [],
    schema =>
      keys(schema.fields).filter(
        key => schema.fields[key].type === FieldType.boolean,
      ),
  )

  return missingBooleanFields.reduce(
    (acc, field) => {
      if (acc[field] === undefined) {
        acc[field] = false
      }
      return acc
    },
    { ...record },
  )
}

export const transformFromExpressionToView = ({
  expression,
  record,
  prop,
  role,
  componentIsInput,
  logger,
  getSchema,
}: {
  expression: string
  record: Record<any, any>
  prop: string
  role: string
  componentIsInput?: boolean
  logger: Logger
  getSchema: DatabindingApiContext['getSchema']
}): { value: string; propPath: string; isError?: boolean } => {
  try {
    const expressionValue = evaluateExpression({
      expression,
      variables: addMissingBooleanFields(record, getSchema),
      functions: getExpressionFunctions()!,
    })
    if (
      typeof expressionValue === 'object' &&
      expressionValue !== null &&
      'html' in expressionValue &&
      'alignment' in expressionValue &&
      Object.keys(expressionValue).length === 2
    ) {
      const { html, alignment } = expressionValue
      const propPath = prop === '$text' ? 'html' : prop
      const value = transformFromRecordToView({
        value: html,
        role,
        componentIsInput,
        propPath,
      })
      return {
        value: `<p class="wixui-rich-text__text" style="text-align: ${alignment};">${value}</p>`,
        propPath,
      }
    } else {
      const propPath = prop === '$text' ? 'text' : prop
      return {
        value: transformFromRecordToView({
          value: expressionValue,
          role,
          componentIsInput,
          propPath,
        }),
        propPath,
      }
    }
  } catch (err) {
    logger.log(`Failed to evaluate expression: ${err}`)
    return {
      propPath: prop === '$text' ? 'text' : prop,
      value: '',
      isError: true,
    }
  }
}
